body {
  margin: 0;
  background-color: black !important;
  font-family: 'Roboto';
}

/* .header {
  color: white;
  background-color: black;
  width: 100%;
  height: 80px;
  position: sticky;
  top: 0;
  left: 0;
  padding-top: 1.5%;
  padding-left: 5%;
  z-index: 9999;
} */

header {
  position: fixed;
  top: 0;
  left: 0;
  padding-left: 5%;
  width: 100%;
  height: 80px;
  background-color: black;
  color: white;
  display: flex;
  justify-content: left;
  align-items: center;
  opacity: 0.9;
  transition: opacity 0.2s ease-in-out;
  z-index: 9999;
}

.title {
  max-width: fit-content;
}

.title:hover {
  color: gray;
  cursor: pointer;
  transition: all 0.4s ease-in-out;
}

/* Gallery Styles */
.img-gallery {
  margin-top: 4.6%;
  padding: 2%;
}

.gallery-item img {
  display: block;
  transition: all 0.4s ease-in-out;
}

.gallery-item img:hover {
  filter: brightness(20%);
  cursor: pointer;
}


.gallery-item .img-description {
  line-height: 20%;
  transition: all 0.4s ease-in-out;
}

.gallery-item img:hover ~ .img-description {
  color: azure;
}

.gallery-item img:hover ~ .img-description .sold {
  color: red;
}

img .overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0;
  transition: all 0.2s ease-in-out;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

img:hover .overlay {
  opacity: 1;
}

img .overlay p {
  color: #fff;
font-size: 1.5rem;
text-align: center;
}

/* For IMG when modal isOpen */
.modal {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.9);
}

.img-isOpen {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.img-isOpen:hover {
  filter: none;
}



/* LandingPage Styles */

.video {
  position: absolute;
  top: 35%;
  right: 30%;
  transform: translate(50%, -50%);
  /* width: 400px; */
  max-width: 100%;
  height: auto;
}

.content {
  position: absolute;
  top: 60%;
  left: 5%;
  transform: translateY(-50%);
  padding: 0 20px;
}

.main-heading {
  color: white;
  margin: 0;
}

.buttons {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-left: 2%;
  margin-right: 2%;
  padding-right: 10%;
  padding-left: 5%;
  margin-top: 1%;
  margin-bottom: 2%;
  max-width: 100%;
}

.btn {
  color: white;
  border-color: white;
  margin-right: 5%;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 10%;
  padding-right: 10%;
  white-space: nowrap;
  border: none;
}

.btn-icon {
  margin-right: 10%;
  transform: translate(-50%);
}

.btn:hover {
  color: black;
  background-color: white;
}

/* footer styles */
footer {
  position: sticky;
  width: 100%;
  height: auto;
  background-color: black;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  font-size: 14px;
  bottom: 0;
}

.left-icons {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-left: 5%;
}

.icon {
  color: white;
  font-size: 20px;
  margin-right: 10px;
}

.icon:hover {
  color: gray;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.copy {
  margin-left: 10px;
  text-align: center;
}

.right {
  text-align: right;
  margin-right: 5%;
}

.right a {
  text-decoration: none;
  color: white;
}

.right a:hover {
  text-decoration: underline;
  color: gray;
  transition: all 0.4s ease-in-out;
}

/* Media queries */
@media only screen and (max-width: 767px) {
  .header {
    z-index: 2;
  }

  .img-gallery {
    margin-top: 18%;
  }

  .video {
    position: relative;
    top: 60px;
    right: 0;
    transform: none;
    width: 100%;
    height: auto;
    z-index: 1;
  }

  .content {
    position: relative;
    margin-bottom: 130px;
    top: 0;
    left: 0;
    transform: none;
    padding: 20px;
    text-align: center;
    z-index: 2;
  }

  .buttons {
    top: 0;
    margin-bottom: 10%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }

  .btn {
    width: 100%;
    padding: 5px;
    font-size: 14px;
    margin-bottom: 0;
  }

  .btn-icon {
    margin-right: 10px;
  }

  footer {
    /* position: fixed; */
    position: sticky;
    /* margin-top: 1%; */
    bottom: 0;
    width: 100%;
    z-index: 9999;
  }
}